import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const ReferralSuccessChart = ({
  totalCustomers,
  successRate,
  refusalRate,
  pendingRate,
}) => {
  const data = {
    labels: ["Clients acceptés", "Clients en attente", "Clients refusés"],
    datasets: [
      {
        data: [successRate, pendingRate, refusalRate],
        backgroundColor: ["#2F7EFF", "#fdcb6e", "#FF3A75"],
        hoverBackgroundColor: ["#2F7EFF", "#fdcb6e", "#FF3A75"],
      },
    ],
  };

  const options = {
    cutout: "80%",
    rotation: -0.5 * Math.PI,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div className="relative w-36 h-36 mx-auto">
      <Doughnut data={data} options={options} />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl font-bold text-center">
        {totalCustomers}
        <p className="text-sm">Clients</p>
      </div>
    </div>
  );
};

export default ReferralSuccessChart;
