import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import authService from "../services/authService";
import { useAuth } from "../../../context/AuthContext";
import api from "../../../services/api.js";
import AuthForm from "../components/AuthForm";

const RegisterUser: React.FC = () => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { setCurrentUser } = useAuth();
    const location = useLocation();
    const [apiError, setApiError] = useState(false);
    const [addressSuggestions, setAddressSuggestions] = useState([]);

    const password = watch("password", "");

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const signature = params.get("signature");

        api.get(`/auth/sign-up?signature=${signature}`).then((response) => {
            setValue("signature", signature);
        }).catch((error) => {
            console.error("Error verifying signature", error);
            setApiError(true);
        });
    }, [location.search, setValue]);

    const onSubmit = async (data: any) => {
        try {
            const response = await authService.registerUser(data);
            setCurrentUser(response.user);
            navigate("/default");
        } catch (error) {
            console.error("Registration failed", error);
            const errorMessage = error.response?.data?.message || "Une erreur est survenue, veuillez réessayer.";
            toast.error(errorMessage);
        }
    };

    const handleAddressChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setValue("address", query);
        if (query.length > 2) {
            try {
                const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${query}`);
                setAddressSuggestions(response.data.features);
            } catch (error) {
                console.error("Error fetching address suggestions", error);
            }
        } else {
            setAddressSuggestions([]);
        }
    };

    const handleAddressSelect = (suggestion: any) => {
        setValue("address", suggestion.properties.label);
        setAddressSuggestions([]);
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <Toaster position="top-center" reverseOrder={false} />
            <div className="bg-white p-8 rounded-lg w-full max-w-md">
                {apiError ? (
                    <>
                        <div className="absolute left-0 top-10 text-center w-full">
                            <h1 className="text-3xl font-bold">Fideliz</h1>
                        </div>
                        <p className="text-gray-700 text-center font-semibold">
                            Ce lien a déjà été utilisé ou est expiré.
                            <br /> Veuillez contacter Fideliz pour plus d'informations.
                        </p>
                        <div onClick={() => navigate('/login')} className="w-1/2 py-2 px-4 bg-blue-500 text-white font-semibold text-center mx-auto rounded-lg mt-4 cursor-pointer">
                            <p>Se connecter</p>
                        </div>
                    </>
                ) : (
                    <>
                        <h2 className="text-2xl font-bold mb-6 text-center">S'inscrire</h2>
                        <AuthForm
                            onSubmit={onSubmit}
                            register={register}
                            handleSubmit={handleSubmit}
                            errors={errors}
                            fields={[
                                { label: "Prénom", type: "text", name: "firstName", validation: { required: "Le prénom est requis", pattern: { value: /^[A-Za-zÀ-ÿ '-]+$/, message: "Le prénom ne peut pas contenir de chiffres" } } },
                                { label: "Nom", type: "text", name: "lastName", validation: { required: "Le nom est requis", pattern: { value: /^[A-Za-zÀ-ÿ '-]+$/, message: "Le nom ne peut pas contenir de chiffres" } } },
                                { label: "Email", type: "email", name: "email", validation: { required: "L'email est requis", pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Adresse email invalide" } } },
                                { label: "Mot de passe", type: "password", name: "password", validation: { required: "Le mot de passe est requis", minLength: { value: 6, message: "Le mot de passe doit contenir au moins 6 caractères" } } },
                                { label: "Confirmer le mot de passe", type: "password", name: "confirmPassword", validation: { required: "La confirmation du mot de passe est requise", validate: (value: string) => value === password || "Les mots de passe ne correspondent pas" } },
                                { label: "Adresse postale", type: "text", name: "address", validation: { required: "L'adresse est requise" }, onChange: handleAddressChange, showSuggestions: true },
                                { label: "Numéro de téléphone", type: "tel", name: "phone", validation: { required: "Le numéro de téléphone est requis", pattern: { value: /^0[0-9]{9}$/, message: "Le numéro de téléphone doit commencer par 0 et contenir exactement 10 chiffres" }, maxLength: { value: 10, message: "Le numéro de téléphone doit contenir exactement 10 chiffres" } } },
                            ]}
                            addressSuggestions={addressSuggestions}
                            handleAddressSelect={handleAddressSelect}
                            submitButtonText="S'inscrire"
                        />
                        <p className="mt-4 text-center font-semibold">
                            Déjà un compte ?{" "}
                            <a href="/login" className="text-blue-600 underline">
                                Se connecter
                            </a>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

export default RegisterUser;
