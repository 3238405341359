import React from "react";
import { format, subMonths, addMonths, isBefore, isAfter } from "date-fns";
import { fr } from "date-fns/locale";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

function DateSelector({ currentMonth, onChange, startMonth, endMonth }) {
  const handlePrevMonth = () => {
    const newMonth = subMonths(currentMonth, 1);
    if (!isBefore(newMonth, startMonth)) {
      onChange(newMonth);
    }
  };

  const handleNextMonth = () => {
    const newMonth = addMonths(currentMonth, 1);
    if (!isAfter(newMonth, endMonth)) {
      onChange(newMonth);
    }
  };

  const isPrevDisabled = isBefore(subMonths(currentMonth, 1), startMonth);
  const isNextDisabled = isAfter(addMonths(currentMonth, 1), endMonth);

  return (
    <div className="flex justify-center items-center mb-4">
      <button
        onClick={handlePrevMonth}
        className={`px-4 py-3 bg-gray-200 hover:bg-gray-300 rounded-lg ${
          isPrevDisabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={isPrevDisabled}
      >
        <FaArrowLeft />
      </button>
      <span className="px-4 py-2 text-lg font-bold border-gray-200">
        {format(currentMonth, "MMMM yyyy", { locale: fr })
          .charAt(0)
          .toUpperCase() +
          format(currentMonth, "MMMM yyyy", { locale: fr }).slice(1)}
      </span>
      <button
        onClick={handleNextMonth}
        className={`px-4 py-3 bg-gray-200 hover:bg-gray-300 rounded-lg ${
          isNextDisabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={isNextDisabled}
      >
        <FaArrowRight />
      </button>
    </div>
  );
}

export default DateSelector;