import React from "react";
import Logo from "../../../common/assets/blue-logo.svg";

const UseMobileApp = () => {
  const isMaintenance = false;

  return (
    <>
      {isMaintenance ? (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4 md:px-4 py-4 md:py-4">
          <div className="fixed top-10 md:left-10 w-full flex md:justify-start justify-center mb-6">
            <img src={Logo} alt="Logo" className="h-5 md:h-7" />
          </div>
          <h1 className="text-xl font-semibold mb-4 text-center">
            Notre site web est en maintenance pour le moment
          </h1>
          <h2 className="text-md mb-6 text-center text-gray-600">
            Pour une meilleur expérience utilisateur, nous avons décidés de
            créer une application mobile.
            <br /> Nous sommes actuellement entrain de vous mettre à disposition l'application.
          </h2>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4 md:px-4 py-4 md:py-4">
          <div className="fixed top-10 md:left-10 w-full flex md:justify-start justify-center mb-6">
            <img src={Logo} alt="Logo" className="h-5 md:h-7" />
          </div>
          <h1 className="text-xl font-semibold mb-4 text-center">
            Veuillez télécharger notre application mobile
          </h1>
          <h2 className="text-md mb-6 text-center text-gray-600">
            Pour une meilleur expérience utilisateur, nous avons décidés de
            créer une application mobile.
            <br /> Pour parrainner de nouveaux clients, veuillez télécharger
            l'applicaton mobile{" "}
          </h2>
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <a
              href="https://apps.apple.com/fr/app/fideliz-fr/id6538715213"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://finfrog.fr/static-pages/images/landing/app-ios.png"
                alt="Download on the App Store"
                className="w-36"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.fideliz.app&pcampaignid=web_share"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://finfrog.fr/static-pages/images/landing/app-android.png"
                alt="Get it on Google Play"
                className="w-36"
              />
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default UseMobileApp;
