import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import authService from "../services/authService";
import { useAuth } from "../../../context/AuthContext";
import AuthForm from "../components/AuthForm";

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { setCurrentUser } = useAuth();

  const onSubmit = async (data: any) => {
    try {
      const response = await authService.login(data.email, data.password);
      setCurrentUser(response.user);
      navigate("/default");
    } catch (error) {
      toast.error("E-mail ou mot de passe incorrect");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <Toaster />
      <div className="absolute top-10 text-center w-full">
        <h1 className="text-3xl font-bold">Fideliz</h1>
      </div>
      <div className="bg-white p-8 rounded-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Se connecter</h2>
        <AuthForm
          onSubmit={onSubmit}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          fields={[
            {
              label: "Email",
              type: "email",
              name: "email",
              validation: { required: "Email est requis" },
            },
            {
              label: "Mot de passe",
              type: "password",
              name: "password",
              validation: { required: "Mot de passe est requis" },
            },
          ]}
          submitButtonText="Se connecter"
        />
        <p
          className="mt-4 text-center text-blue-600 font-semibold underline cursor-pointer"
          onClick={() => navigate("/forgot-password")}
        >
          Mot de passe oublié ?
        </p>
      </div>
    </div>
  );
};

export default Login;
