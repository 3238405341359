// src/common/components/Navbar.tsx
import React, { useState, useEffect } from "react";
import {
  FaTachometerAlt,
  FaUsers,
  FaDollarSign,
  FaLifeRing,
  FaSignOutAlt,
  FaBars,
  FaArrowLeft,
  FaArrowRight,
  FaHospitalUser,
  FaHouseUser,
  FaFileInvoice,
  FaGift,
} from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import InitialIcon from "./InitialIcon";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.svg";
import Icon from "../assets/icon.svg";

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    JSON.parse(localStorage.getItem("isSidebarOpen") || "true")
  );
  const location = useLocation();
  const { currentUser } = useAuth();

  const userInitials = `${currentUser?.first_name
    .charAt(0)
    .toUpperCase()}${currentUser?.last_name.charAt(0).toUpperCase()}`;

  const opticianMenuItems = currentUser.stripe_subscription_id
    ? currentUser.seller_type === "sponsorship"
      ? [
          {
            icon: <FaTachometerAlt className="text-2xl" />,
            label: "Tableau de bord",
            to: "/optician-dashboard",
          },
          {
            icon: <FaHospitalUser className="text-2xl" />,
            label: "Mes ventes",
            to: "/customers",
          },
          {
            icon: <FaUsers className="text-2xl" />,
            label: "Mes parraineurs",
            to: "/sponsors",
          },
          {
            icon: <FaGift className="text-2xl" />,
            label: "Mes bons d'achats",
            to: "/bons",
          },
          {
            icon: <FaDollarSign className="text-2xl" />,
            label: "Abonnement",
            to: "/plan",
          },
          {
            icon: <FaFileInvoice className="text-2xl" />,
            label: "Mes factures",
            to: "/invoices",
          },
          {
            icon: <FaHouseUser className="text-2xl" />,
            label: "Espace employé",
            to: "/workers",
          },
          {
            icon: <FaLifeRing className="text-2xl" />,
            label: "Support",
            to: "/optician-support",
          },
          {
            icon: <FaSignOutAlt className="text-2xl" />,
            label: "Se déconnecter",
            to: "#",
            onClick: () => {
              localStorage.removeItem("token");
              window.location.href = "/login";
            },
          },
        ]
      : [
          {
            icon: <FaTachometerAlt className="text-2xl" />,
            label: "Tableau de bord",
            to: "/optician-dashboard",
          },
          {
            icon: <FaHospitalUser className="text-2xl" />,
            label: "Mes ventes",
            to: "/customers",
          },
          {
            icon: <FaUsers className="text-2xl" />,
            label: "Mes vendeurs",
            to: "/sponsors",
          },
          {
            icon: <FaDollarSign className="text-2xl" />,
            label: "Abonnement",
            to: "/plan",
          },
          {
            icon: <FaLifeRing className="text-2xl" />,
            label: "Support",
            to: "/optician-support",
          },
          {
            icon: <FaSignOutAlt className="text-2xl" />,
            label: "Se déconnecter",
            to: "#",
            onClick: () => {
              localStorage.removeItem("token");
              window.location.href = "/login";
            },
          },
        ]
    : [
        {
          icon: <FaDollarSign className="text-2xl" />,
          label: "Abonnement",
          to: "/plan",
        },
        {
          icon: <FaSignOutAlt className="text-2xl" />,
          label: "Se déconnecter",
          to: "#",
          onClick: () => {
            localStorage.removeItem("token");
            window.location.href = "/login";
          },
        },
      ];

  const userMenuItems = [
    {
      icon: <FaTachometerAlt className="text-2xl" />,
      label: "Tableau de bord",
      to: "/dashboard",
    },
    {
      icon: <FaLifeRing className="text-2xl" />,
      label: "Support",
      to: "/support",
    },
    {
      icon: <FaSignOutAlt className="text-2xl" />,
      label: "Se déconnecter",
      to: "#",
      onClick: () => {
        localStorage.removeItem("token");
        window.location.href = "/login";
      },
    },
  ];

  const menuItems =
    currentUser.role === "optician" ? opticianMenuItems : userMenuItems;

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleSidebar = () => {
    const newState = !isSidebarOpen;
    setIsSidebarOpen(newState);
    localStorage.setItem("isSidebarOpen", JSON.stringify(newState));
  };

  useEffect(() => {
    // Close the mobile menu on location change
    setIsMobileMenuOpen(false);
  }, [location]);

  return (
    <div>
      {/* Mobile Navbar */}
      <div className="md:hidden bg-blue-500 text-white flex justify-between items-center p-4">
        <img src={Logo} style={{ marginLeft: 15 }} alt="Logo" className="h-6" />
        <button onClick={toggleMobileMenu} className="focus:outline-none">
          {isMobileMenuOpen ? (
            <FaArrowLeft className="text-2xl" />
          ) : (
            <FaBars className="text-2xl" />
          )}
        </button>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden bg-blue-500 text-white flex flex-col p-4 space-y-2 overflow-y-auto">
          {menuItems.map((item) => (
            <Link key={item.to} to={item.to} onClick={item.onClick}>
              <div
                className={`py-3 px-4 cursor-pointer flex items-center ${
                  location.pathname === item.to
                    ? "bg-blue-400"
                    : "hover:bg-blue-600"
                }`}
              >
                {item.icon}
                <span className="ml-3 text-md">{item.label}</span>
              </div>
            </Link>
          ))}
          <div className="mt-4 flex items-center">
            <InitialIcon initials={userInitials} />
            <div className="ml-3">
              <p className="font-semibold">
                {currentUser.first_name.toUpperCase()}{" "}
                {currentUser.last_name.toUpperCase()}
              </p>
              <p className="text-sm">{currentUser.optician_name}</p>
            </div>
          </div>
        </div>
      )}

      {/* Desktop Sidebar */}
      <div
        className={`hidden md:flex flex-col h-screen ${
          isSidebarOpen ? "w-60" : "w-20"
        } bg-blue-500 text-white transition-all duration-300`}
      >
        <div
          className={`flex items-center ${
            isSidebarOpen ? "justify-between px-4" : "justify-center"
          } h-16 mt-4`}
        >
          {isSidebarOpen ? (
            <img src={Logo} alt="Logo" className="h-6" />
          ) : (
            <img
              src={Icon}
              style={{ marginRight: 10 }}
              alt="Icon"
              className="h-4"
            />
          )}
          <button onClick={toggleSidebar} className="focus:outline-none">
            {isSidebarOpen ? (
              <FaArrowLeft className="text-2xl" />
            ) : (
              <FaArrowRight className="text-md" />
            )}
          </button>
        </div>
        <nav className="flex-1 mt-10">
          <ul className="space-y-2">
            {menuItems.map((item) => (
              <Link key={item.to} to={item.to} onClick={item.onClick}>
                <li
                  className={`py-3 px-4 cursor-pointer flex items-center ${
                    isSidebarOpen ? "" : "justify-center"
                  } ${
                    location.pathname === item.to
                      ? "bg-blue-400"
                      : "hover:bg-blue-600"
                  }`}
                >
                  {item.icon}
                  {isSidebarOpen && (
                    <span className="ml-3 text-md whitespace-nowrap">
                      {item.label}
                    </span>
                  )}
                </li>
              </Link>
            ))}
          </ul>
        </nav>
        {isSidebarOpen ? (
          <div className="p-4 m-5 bg-blue-600 h-44 rounded-lg mt-auto flex flex-col justify-between">
            <div className="flex justify-between items-center">
              <InitialIcon initials={userInitials} />
            </div>

            <div className="mt-auto">
              <p className="font-semibold">
                {currentUser.first_name.toUpperCase()}{" "}
                {currentUser.last_name.toUpperCase()}
              </p>
              <p className="text-sm">{currentUser.optician_name}</p>
            </div>
          </div>
        ) : (
          <div className="flex justify-center mt-auto mb-32">
            <InitialIcon initials={userInitials} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
