import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { motion } from "framer-motion";
import Navbar from "../common/components/NavBar";

const Page = ({ children }) => {
    const { currentUser } = useAuth();
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    function capitalizeFirstLetter(string) {
        return string?.charAt(0).toUpperCase() + string.slice(1);
    }

    const capitalizeDate = (dateString) => {
        return dateString
            .split(" ")
            .map((word) => capitalizeFirstLetter(word))
            .join(" ");
    };

    const formattedDate = capitalizeDate(
        format(currentDate, "eeee dd MMMM yyyy, HH:mm:ss", { locale: fr })
    );

    const containerVariants = {
        hidden: { y: -100 },
        visible: {
            y: 0,
            transition: {
                duration: 0.4,
            },
        },
    };

    const childrenVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.7,
                delay: 0.5,
            },
        },
    };

    return (
        <div className="flex flex-col md:flex-row md:h-screen">
            <Navbar />
            <div className="flex-1 overflow-y-auto p-8 bg-gray-100 mt-0">
                <motion.div
                    className="flex justify-between items-center rounded-b-lg"
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                >
                    <div>
                        <h1 className="text-2xl">
                            Bonjour{" "}
                            <span className="font-bold">
                                {currentUser.role !== "user"
                                    ? capitalizeFirstLetter(currentUser.optician_name)
                                    : capitalizeFirstLetter(currentUser.first_name)}
                            </span>{" "}
                            !
                        </h1>
                    </div>
                    {currentUser.role !== "user" && (
                        <div>
                            <p className="text-gray-500 text-sm mt-1">📆 {formattedDate}</p>
                        </div>
                    )}
                </motion.div>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={childrenVariants}
                >
                    {children}
                </motion.div>
            </div>
        </div>
    );
};

export default Page;
