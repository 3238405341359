import React, { useState } from "react";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const Profile = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { setCurrentUser, currentUser } = useAuth();
  const authToken = localStorage.getItem("token");
  const stripe = useStripe();
  const elements = useElements();

  const handleUnsubscribe = async () => {
    setLoading(true);
    try {
      const data = await api.get("/users/cancel-subscription", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setCurrentUser(data.data.user);
      navigate("/default");
    } catch (error) {
      setMessage("Failed to cancel subscription");
    } finally {
      setLoading(false);
    }
  };

  const handleChangeCard = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log("Form submitted");

    if (!stripe || !elements) {
      console.log("Stripe or elements not loaded");
      return;
    }

    try {
      const cardElement = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        setMessage("Failed to create token");
        setLoading(false);
        return;
      }

      await api.post(
        "/users/update-payment-method",
        {
          cardToken: token.id,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      navigate("/default");
    } catch (error) {
      console.log("API error:", error);
      setMessage("Failed to update payment method");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-screen">
      <div className="w-2/5 mx-auto mt-36 bg-white p-5 rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-4">Mon profil</h2>
        <div className="mb-4">
          <button
            onClick={() => navigate("/plan")}
            className="w-full bg-green-500 mb-2 text-white py-2 px-4 rounded"
          >
            Changer d'offre
          </button>
          <button
            onClick={handleUnsubscribe}
            className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            disabled={loading}
          >
            {loading ? "Processing..." : "Se désabonner"}
          </button>
        </div>
        <CardElement
          id="cardNumber"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <form onSubmit={handleChangeCard} className="mb-4">
          <div className="mb-4">
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            disabled={loading}
          >
            {loading ? "Processing..." : "Valider de carte"}
          </button>
        </form>
        {message && (
          <div className="mt-4 text-center text-gray-700">{message}</div>
        )}
      </div>
      <div className="w-2/5 mx-auto mt-36 bg-white p-5 rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-4">Votre boutique</h2>
        <p>{currentUser.optician_name}</p>
      </div>
    </div>
  );
};

export default Profile;
