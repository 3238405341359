import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";

function QRCode() {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUrlAndRedirect = async () => {
      try {
        const response = await api.get("/qr-code/generate-link");
        const data = await response.data.url
        console.log(data);
        if (data) {
          navigate(data);
        } else {
          throw new Error("URL not found");
        }
      } catch (error) {
        console.error("Failed to fetch URL:", error);
      }
    };
    fetchUrlAndRedirect();
  }, []);

  return <div>Loading...</div>;
}

export default QRCode;
