// src/features/support/pages/Support.tsx
import React from "react";
import { useAuth } from "../../../context/AuthContext";
import Page from "../../../layouts/Page";
import ContactForm from "../components/ContactForm";
import FAQItem from "../components/FAQItem";
import { FaWhatsapp } from "react-icons/fa";

const faqs = [
    {
        question: "Comment puis-je contacter le support client ?",
        answer:
            "Vous pouvez contacter le support client via notre formulaire de contact ou via What's app si vous êtes abonnés à l'abonnement Premium.",
    },
    {
        question: "Quels sont les horaires du support ?",
        answer: "Notre support est disponible du lundi au samedi, de 9h à 19h.",
    },
    {
        question: "Pourquoi le statut de mon client est bloqué par Fideliz ?",
        answer:
            "Votre parrainneur a réfusé la demande de rejet du client. Nous allons étudier le dossier et revenir vers vous dans les prochaines 48 heures.",
    },
    {
        question:
            "Pourquoi la liste des parrainneurs ne contient pas la personne que je viens d'inviter ?",
        answer:
            "La liste des parrainneurs contient uniquement les personnes ayant acceptés votre invitation. La liste sera mise à jour lors de l'acceptation de votre futur parrainneur.",
    },
];

const Support: React.FC = () => {
    const { currentUser } = useAuth();

    const openWhatsApp = () => {
        const phoneNumber = "+33667597561";
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, "_blank");
    };

    return (
        <Page>
            <div className="mt-6 border-t-2 border-l-neutral-800">
                <div className="flex flex-col md:flex-row justify-between items-center mb-8 px-8 py-6 mt-6 bg-black rounded-xl">
                    <div>
                        <h2 className="text-3xl font-bold text-white">Support</h2>
                        <p className="text-white font-semibold mt-2">
                            Entrez en contact avec Fideliz
                        </p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row w-full">
                    <div
                        className={`bg-white flex flex-col px-8 py-6 mb-8 rounded-xl md:w-3/5`}
                    >
                        <h3 className="text-2xl font-bold mb-6">Contactez-nous</h3>
                        <ContactForm />
                    </div>
                        <div className="bg-white px-8 py-14 mb-8 md:w-2/5 rounded-xl ml-0 md:ml-3 flex flex-col items-center justify-between">
                            <div className="flex">
                                <FaWhatsapp className="mr-2 text-3xl text-green-500" />
                                <h3 className="text-2xl font-bold mb-2">What's App</h3>
                            </div>
                            <div className="flex justify-center items-center text-center flex-col">
                                <p>Contactez nous directement sur What's app</p>
                                <p className="text-sm my-3 font-semibold text-center">
                                    Vous pouvez nous contacter directement sur What's App grâce à
                                    à votre abonnement Premium. Nous vous garentissons une
                                    réponse dans l'heure.
                                </p>
                            </div>
                            <button
                                onClick={openWhatsApp}
                                className="bg-green-500 mx-auto w-full hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Ouvrir sur What's App
                            </button>
                        </div>
                </div>
                {currentUser.role !== "user" && (
                    <div className="px-8 py-6 bg-white rounded-xl">
                        <h3 className="text-2xl font-bold mb-4">FAQ</h3>
                        {faqs.map((faq, index) => (
                            <FAQItem key={index} question={faq.question} answer={faq.answer} />
                        ))}
                    </div>
                )}
            </div>
        </Page>
    );
};

export default Support;
