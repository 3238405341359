import React from 'react';
import { Voucher } from '../types';

interface VoucherListProps {
    vouchers: Voucher[];
    handleAccept: (voucherId: number) => void;
    capitalizeFirstLetter: (str: string) => string;
}

const VoucherList: React.FC<VoucherListProps> = ({
    vouchers,
    handleAccept,
    capitalizeFirstLetter
}) => {
    const sortedVouchers = vouchers.sort((a, b) => Number(a.is_used) - Number(b.is_used));

    return (
        <div className="w-full flex rounded-xl flex-wrap justify-center bg-white">
            <h2 className="mt-5 mr-auto ml-8 font-semibold text-xl">Clients</h2>
            <div
                className="w-full bg-white rounded p-3 my-2 flex flex-col md:flex-row md:justify-between md:items-center md:px-8"
                style={{ fontSize: "0.8rem" }}
            >
                <div className="font-bold mb-2 md:mb-0">Date</div>
                <div className="md:w-1/12 font-bold mb-2 md:mb-0">Nom</div>
                <div className="md:w-1/12 font-bold mb-2 md:mb-0">Prénom</div>
                <div className="md:w-1/12 font-bold mb-2 md:mb-0">Valeur</div>
                <div className="md:w-1/12 font-bold mb-2 md:mb-0">Actions</div>
            </div>
            {sortedVouchers.map((voucher) => (
                <div
                    key={voucher.id}
                    className="bg-white rounded border-t py-5 mx-8 w-full flex flex-col md:flex-row md:justify-between md:items-center"
                    style={{ fontSize: "0.8rem" }}
                >
                    <div className="mb-2 md:mb-0 font-semibold text-xs">
                        {new Date(voucher.created_at).toLocaleDateString("fr-FR", {
                            day: "2-digit",
                            month: "2-digit"
                        })}
                    </div>
                    <div className="md:w-1/12 mb-2 md:mb-0 font-bold text-xs">
                        {capitalizeFirstLetter(voucher.last_name)}
                    </div>
                    <div className="md:w-1/12 mb-2 md:mb-0 font-bold text-xs">
                        {capitalizeFirstLetter(voucher.first_name)}
                    </div>
                    <div className="md:w-1/12 mb-2 md:mb-0 font-semibold text-xs">
                        {voucher.value} €
                    </div>
                    <div className="md:w-1/12 mb-2 md:mb-0 font-semibold text-xs">
                        <button
                            onClick={() => handleAccept(voucher.id)}
                            disabled={voucher.is_used}
                            className={`${voucher.is_used ? "bg-gray-200 text-gray-600" : "bg-green-200 text-green-600"
                                } flex items-center justify-center px-4 py-1 rounded-lg w-full`}
                        >
                            <p className="font-semibold">
                                {voucher.is_used ? `Utilisé le ${new Date(voucher.updated_at).toLocaleDateString("fr-FR", {
                                    day: "2-digit",
                                    month: "2-digit"
                                })}` : 'Accepter'}
                            </p>
                        </button>
                    </div>
                </div>
            ))}
            {vouchers.length === 0 && (
                <p className="font-bold mb-6 text-center mt-8">
                    Aucun bon d'achat pour le moment
                </p>
            )}
        </div>
    );
};

export default VoucherList;
