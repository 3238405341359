import React from "react";
import { UseFormRegister } from "react-hook-form";

interface InputFieldProps {
  label: string;
  type: string;
  name: string;
  register: UseFormRegister<any>;
  validation?: Record<string, any>;
  error?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  name,
  register,
  validation,
  error,
  onChange,
  inputProps,
}) => {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-light text-gray-700">
        {label}
      </label>
      <input
        type={type}
        id={name}
        {...register(name, validation)}
        onChange={onChange}
        {...inputProps}
        className={`mt-1 block w-full py-3 px-4 border ${
          error ? "border-red-500" : "border-gray-300"
        } rounded-md`}
      />
      {error && <p className="text-xs text-red-600">{error.message}</p>}
    </div>
  );
};

export default InputField;
