import React from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import authService from "../services/authService";
import AuthForm from "../components/AuthForm";

const ForgotPassword: React.FC = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async (data: any) => {
        try {
            await authService.forgetPassword(data.email);
            toast.success("Un lien de réinitialisation a été envoyé à votre adresse e-mail.");
            reset();
        } catch (error) {
            toast.error("Erreur lors de l'envoi du lien de réinitialisation.");
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <Toaster />
            <div className="absolute top-10 text-center w-full">
                <h1 className="text-3xl font-bold">Fideliz</h1>
            </div>
            <div className="bg-white p-8 rounded-lg w-full max-w-md">
                <h2 className="text-2xl font-bold mb-6 text-center">Mot de passe oublié</h2>
                <AuthForm
                    onSubmit={onSubmit}
                    register={register}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    fields={[{ label: "Email", type: "email", name: "email", validation: { required: "Email est requis" } }]}
                    submitButtonText="Envoyer"
                />
            </div>
        </div>
    );
};

export default ForgotPassword;
