import React from "react";
import { Invoice } from "../types/index";

interface InvoiceListProps {
  invoices: Invoice[];
}

const InvoiceList: React.FC<InvoiceListProps> = ({ invoices }) => {
  const getStatusLabel = (status: string) => {
    switch (status) {
      case "draft":
        return (
          <span className="bg-yellow-100 text-yellow-600 flex items-center w-full justify-center px-2 py-1 rounded-lg font-semibold">
            Brouillon
          </span>
        );
      case "open":
        return (
          <span className="bg-blue-100 text-blue-600 flex items-center w-full justify-center px-2 py-1 rounded-lg font-semibold">
            Attente du paiement
          </span>
        );
      case "uncollectible":
        return (
          <span className="bg-red-100 text-red-600 flex items-center w-full justify-center px-2 py-1 rounded-lg font-semibold">
            Irrecouvrable
          </span>
        );
      case "paid":
        return (
          <span className="bg-green-100 text-green-600 flex items-center w-full justify-center px-2 py-1 rounded-lg font-semibold">
            Payée
          </span>
        );
      case "void":
        return (
          <span className="bg-gray-100 text-gray-600 flex items-center w-full justify-center px-2 py-1 rounded-lg font-semibold">
            Annulée
          </span>
        );
      case "deleted":
        return (
          <span className="bg-black-100 text-black-600 flex items-center w-full justify-center px-2 py-1 rounded-lg font-semibold">
            Supprimée
          </span>
        );
      default:
        return (
          <span className="bg-gray-200 text-gray-800 flex items-center w-full justify-center px-2 py-1 rounded-lg font-semibold">
            Inconnu
          </span>
        );
    }
  };

  return (
    <div className="px-8 py-6 bg-white w-full rounded-lg">
      {invoices.length === 0 ? (
        <p className="text-center text-gray-500 font-semibold text-xs">
          Aucune facture disponible.
        </p>
      ) : (
        <table className="w-full bg-white">
          <thead>
            <tr>
              <th className="w-1/5 py-2 px-4 border-b-2 border-gray-300 text-left text-gray-700 text-sm">
                Nom de la facture
              </th>
              <th className="w-1/5 text-center py-2 px-4 border-b-2 border-gray-300 text-gray-700 text-sm">
                Montant
              </th>
              <th className="w-1/5 text-center py-2 px-4 border-b-2 border-gray-300 text-gray-700 text-sm">
                Type
              </th>
              <th className="w-1/5 text-center py-2 px-4 border-b-2 border-gray-300 text-gray-700 text-sm">
                Statut
              </th>
              <th className="w-1/5 text-center py-2 px-4 border-b-2 border-gray-300 text-gray-700 text-sm">
                Télécharger
              </th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td className="py-2 px-4 border-b border-gray-300 text-sm">
                  {invoice.title}
                </td>
                <td className="py-2 px-4 border-b border-gray-300 text-sm text-center">
                  {invoice.amount} €
                </td>
                <td className="py-2 px-4 border-b border-gray-300 text-sm text-center">
                  {invoice.isSubscription ? "Abonnement" : "Commissions"}
                </td>
                <td className="py-2 px-4 border-b border-gray-300 text-sm">
                  {getStatusLabel(invoice.status)}
                </td>
                <td className="py-2 px-4 border-b border-gray-300 text-sm">
                  <button
                    onClick={() => window.open(invoice.url, "_blank")}
                    className="bg-blue-100 text-blue-600 flex items-center w-full justify-center px-2 py-1 rounded-lg font-semibold"
                  >
                    Télécharger
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InvoiceList;
