import api from "../../../services/api";

const authService = {
  async login(email: string, password: string) {
    const response = await api.post("/auth/login", { email, password });
    const token = response.data.token.token;

    localStorage.setItem("token", token);
    console.log("Response Data:", response.data);
    console.log("Token:", response.data.token.token);

    return response.data;
  },
  async register(data: any) {
    const response = await api.post(
      `/auth/register/?signature=${data.signature}`,
      data
    );
    const token = response.data.token.token;
    localStorage.setItem("token", token);
    return response.data;
  },
  async registerUser(data: any) {
    const response = await api.post(
      `/auth/sign-up/?signature=${data.signature}`,
      data
    );
    const token = response.data.token.token;
    localStorage.setItem("token", token);
    return response.data;
  },
  async forgetPassword(email: string) {
    const response = await api.post("/auth/forget-password", { email });
    return response.data;
  },
  async checkResetToken(token: string) {
    const response = await api.get(`/auth/reset-password?token=${token}`);
    return response.data;
  },
  async changePassword(token: string, newPassword: string) {
    const response = await api.post("/auth/reset-password", {
      token,
      password: newPassword,
    });
    return response.data;
  },
  async getProfile() {
    const token = localStorage.getItem("token");
    const response = await api.get("/users/me", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  },
};

export default authService;
