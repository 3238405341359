// src/features/support/components/ContactForm.tsx
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import api from "../../../services/api";

interface ContactFormData {
    subject: string;
    message: string;
}

const ContactForm: React.FC = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm<ContactFormData>();

    const onSubmit: SubmitHandler<ContactFormData> = async (data) => {
        try {
            const token = localStorage.getItem("token");
            await toast.promise(
                api.post(
                    `/send-message-support`,
                    { message: data.message, subject: data.subject },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                ),
                {
                    loading: "Envoi en cours ..",
                    success: "Votre demande a été envoyée",
                    error: "Échec de l'envoi du message'",
                }
            );
            reset();
        } catch (error) {
            console.error("Failed to send message", error);
        }
    };

    return (
        <>
            <Toaster />
            <form onSubmit={handleSubmit(onSubmit)} className="mb-8">
                <div className="mb-4">
                    <label
                        className="block text-gray-700 text-base font-bold mb-2"
                        htmlFor="subject"
                    >
                        Sujet
                    </label>
                    <input
                        id="subject"
                        type="text"
                        {...register("subject", { required: "Ce champ est requis." })}
                        className="appearance-none border font-semibold rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {errors.subject && (
                        <span className="text-red-500 text-base italic">
                            {errors.subject.message}
                        </span>
                    )}
                </div>
                <div className="mb-4">
                    <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="message"
                    >
                        Message
                    </label>
                    <textarea
                        id="message"
                        {...register("message", { required: "Ce champ est requis." })}
                        className="appearance-none border rounded w-full font-semibold py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        rows={4}
                        style={{ resize: "none" }}
                    ></textarea>
                    {errors.message && (
                        <span className="text-red-500 text-xs italic">
                            {errors.message.message}
                        </span>
                    )}
                </div>
                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className="bg-blue-500 mx-auto w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Envoyer
                    </button>
                </div>
            </form>
        </>
    );
};

export default ContactForm;
