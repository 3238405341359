import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Page from "../../../layouts/Page";
import { FaUserFriends, FaCalendarDay, FaCashRegister } from "react-icons/fa";
import api from '../../../services/api';

const Worker = () => {

    const [stats, setStats] = useState({
        totalDaySales: 0, // Ventes du jour
        dailyContributors: 0, // Apporteurs du jour
        totalCagnotte: 0, // Cagnotte globale
    });
    // Mock des données pour simuler les ventes, les apporteurs et la cagnotte globale
    const loadStats = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await api.get('/opticians/workers', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setStats(response.data);
        } catch (error) {
            console.error('Failed to load stats', error);
        }
    };



    useEffect(() => {
        loadStats();
    }, []);

    return (
        <Page>
            <Toaster />
            <div className="mt-6 border-t-2 border-l-neutral-800">
                <div className="flex flex-col lg:flex-row justify-between items-center mb-8 px-4 lg:px-8 py-6 mt-6 bg-black rounded-xl">
                    <div className="mb-4 lg:mb-0">
                        <h2 className="text-xl font-bold text-white">
                            Espace employé
                        </h2>
                        <p className="text-white text-xs font-semibold mt-2">
                            Gère ta cagnotte et suis tes performances
                        </p>
                    </div>
                </div>

                {/* Statistiques principales */}
                <div className="text-base flex flex-col md:flex-row md:space-y-0 space-y-4 justify-between w-full mb-6">
                    {/* Ventes du jour */}
                    <div className="flex flex-col justify-center bg-white py-6 px-6 rounded-xl w-full md:w-1/3 md:mr-4">
                        <div className="bg-blue-500 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer mb-4">
                            <FaCalendarDay className="text-white text-xl" />
                        </div>
                        <p className="font-semibold text-lg">Ventes du jour</p>
                        <div className="mt-4 flex items-center justify-between">
                            <p className="font-bold text-2xl">{stats.approvedClients}</p>
                        </div>
                    </div>

                    {/* Apporteurs du jour */}
                    <div className="flex flex-col justify-center bg-white py-6 px-6 rounded-xl w-full md:w-1/3 md:mr-4">
                        <div className="bg-orange-400 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer mb-4">
                            <FaUserFriends className="text-white text-xl" />
                        </div>
                        <p className="font-semibold text-lg">Apporteurs du jour</p>
                        <div className="mt-4 flex items-center justify-between">
                            <p className="font-bold text-3xl">{stats.storeUsers}</p>
                        </div>
                    </div>

                    {/* Cagnotte globale */}
                    <div className="flex flex-col justify-between bg-blue-500 py-6 px-6 rounded-xl w-full md:w-1/3">
                        <div>
                            <div className="bg-blue-300 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer mb-4">
                                <FaCashRegister className="text-white text-xl" />
                            </div>
                            <p className="font-normal text-lg text-white">Cagnotte globale</p>
                        </div>
                        <div className="flex items-center">
                            <p className="font-bold text-3xl text-white">
                                {stats.approvedClientsMonth * 3} €
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Worker;