import React, { useState } from "react";
import api from "../../services/api";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Page from "../../layouts/Page";
import { FaPlusCircle } from "react-icons/fa";

const plans = [
  {
    id: 1,
    name: "Starter",
    price: "Gratuit",
    currency: "",
    text: "Invite jusqu'à 10 parraineurs, profite de notre support par e-mail et accède à tes statistiques de base. Parfait pour les opticiens qui débutent et souhaite se construire un réseau et parraineur",
  },
  {
    id: 3,
    name: "Premium",
    price: "249.90",
    currency: "€",
    text: "Invite autant de parraineurs que tu le souhaite, profite de notre support prioritaire par discussion instantannée. Idéal pour développer votre réseau au maximum et décupler vos ventes.",
  },
];

const PricingPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useAuth();

  const selectPlan = async (plan) => {
    setSelectedPlan(plan.id);
    setLoading(true);

    try {
      const response = await api.post(
        "/users/set-plan",
        {
          plan_level: plan.id,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.status === 200) {
        setMessage({ type: "success", text: "Plan updated successfully" });
        setCurrentUser(response.data.data);
        navigate("/payment");
      } else {
        setMessage({
          type: "error",
          text: response.data.message || "An error occurred",
        });
      }
    } catch (error) {
      setMessage({
        type: "error",
        text:
          error.response?.data?.message ||
          "An error occurred while updating the plan",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUnsubscribe = async () => {
    setLoading(true);
    try {
      const data = await api.get("/users/cancel-subscription", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setCurrentUser(data.data.user);
      navigate("/default");
    } catch (error) {
      setMessage("Failed to cancel subscription");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page>
      <div className="mt-6 border-t-2 border-l-neutral-800">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8 px-8 py-6 mt-6 bg-black rounded-xl">
          <div className="mb-4 md:mb-0">
            <h2 className="text-3xl font-bold text-white">Votre abonnement</h2>
            <p className="text-white font-semibold mt-2">
              Gérer votre abonnement, simplement.
            </p>
          </div>
          {currentUser.stripe_subscription_id && (
            <div className="flex flex-col md:flex-row">
              <button
                onClick={() => navigate("/change-card")}
                className="px-6 py-3 mb-4 md:mb-0 md:mr-4 text-white rounded-lg font-bold bg-blue-500 hover:bg-blue-600"
              >
                Changer d'IBAN
              </button>
              <button
                onClick={() => handleUnsubscribe()}
                className="px-6 py-3 text-white rounded-lg font-bold bg-red-500 hover:bg-red-600"
              >
                Se désabonner
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center justify-start">
        <div className="w-full flex flex-col md:flex-row justify-between">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`p-6 rounded-lg bg-white flex flex-col justify-between mb-6 md:mb-0 md:mx-2 ${currentUser.plan_level === plan.id &&
                currentUser.stripe_subscription_id
                ? "border-2 border-blue-500"
                : ""
                }`}
              style={{ width: "100%", md: "31%" }}
            >
              <div className="flex-grow">
                <div className="border-b-2">
                  <h2 className="text-xl font-semibold mb-4">{plan.name}</h2>
                  <p className="text-3xl font-semibold">
                    {plan.price}
                    <span className="text-xl font-normal">
                      &nbsp;{plan.currency}
                    </span>
                    {plan.id !== 1 && (
                      <span className="text-xl ml-2 font-semibold text-gray-500">
                        /Mois
                      </span>
                    )}

                  </p>
                  <p className="text-blue-500 font-normal text-xs mt-1">
                    + 45€ par vente effectuée grâce à Fideliz
                  </p>
                  <p className="mt-3 mb-4 h-28 text-gray-400 text-sm">
                    {plan.text}
                  </p>
                </div>
                {plan.id === 1 && (
                  <div className="mt-8">
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Invite jusqu'à 10 parraineurs
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Accède aux statistiques de bases
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">Support par e-mail</p>
                    </div>
                  </div>
                )}
                {plan.id === 2 && (
                  <div className="mt-8">
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Invite jusqu'à 10 parraineurs
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Accède aux statistiques avancés
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Support par e-mail prioritaire
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Chat pour discuter avec vos parraineurs
                      </p>
                    </div>
                  </div>
                )}
                {plan.id === 3 && (
                  <div className="mt-8">
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Aucune limite d'invitation de nouveaux parraineurs
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Accède aux statistiques avancés
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Support via What's App
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Chat pour discuter avec vos parraineurs
                      </p>
                    </div>
                    <div className="flex mb-4">
                      <FaPlusCircle className="text-2xl text-blue-500" />
                      <p className="ml-3 font-semibold">
                        Découvre au nouvelles fonctionnalité en priorité
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {currentUser.plan_level === plan.id &&
                currentUser.stripe_subscription_id ? (
                <div className="w-full border rounded-lg flex items-center justify-center py-3 mt-16 bg-blue-500 text-white cursor-default">
                  <p className="font-bold text-xl ">Abonnement actuel</p>
                </div>
              ) : (currentUser.sponsor > 10 && plan.id === 1) ? (
                <div className="w-full border rounded-lg flex items-center justify-center py-3 mt-16 bg-gray-500 text-white cursor-not-allowed">
                  <p className="font-bold text-xl ">Vous avez trop de parrainneurs</p>
                </div>
              ) : (
                <div
                  onClick={() => selectPlan(plan)}
                  className="w-full border rounded-lg flex items-center justify-center py-3 mt-16 hover:bg-blue-500 hover:text-white cursor-pointer"
                >
                  <p className="font-bold text-xl ">Sélectionner</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default PricingPlans;