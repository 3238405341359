import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
  Tooltip,
  Text,
} from "recharts";

const RankingChart = ({ isSeller, data }) => {

  const newData = data?.map((sponsor) => ({
    name: sponsor.name,
    uv: sponsor.totalReferred,
    userImage: sponsor.userImage,
  }));

  // Arrondir le max à la dizaine supérieure
  const maxValue = Math.max(...newData.map((d) => d.uv), 0);
  const maxArrondi = Math.ceil(maxValue / 10) * 10;
  const ticks = [0, maxArrondi / 2, maxArrondi].filter((value, index, self) => self.indexOf(value) === index);

  // Custom Tooltip
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-blue-200 rounded-lg px-4 my-2">
          <p className="label font-semibold">{`${payload[0].value} ${isSeller ? "ventes" : "clients"
            }`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full">
      <h2 className="text-2xl font-semibold mb-6 mt-2">
        {isSeller
          ? "Classement des meilleurs vendeurs du mois"
          : "Classement des meilleurs parrains du mois"}
      </h2>
      {data && (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={newData}
            margin={{ top: 20, right: 30, left: 40, bottom: 40 }}
          >
            <CartesianGrid strokeDasharray="15 15" vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={({ x, y, payload }) => (
                <Text
                  x={x}
                  y={y}
                  dy={15}
                  textAnchor="middle"
                  className="font-semibold"
                >
                  {payload.value}
                </Text>
              )}
            />
            <YAxis
              ticks={ticks}
              domain={[0, maxArrondi]}
              axisLine={false}
              tickLine={false}
              tick={({ x, y, payload }) => (
                <Text
                  x={x}
                  y={y}
                  dx={-10}
                  textAnchor="end"
                  className="font-semibold"
                >
                  {payload.value}
                </Text>
              )}
            />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<CustomTooltip />}
            />
            <Bar dataKey="uv" barSize={45} radius={[10, 10, 0, 0]}>
              {newData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.uv === maxValue ? "#3B82F6" : "#9FC9FC"}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>

      )}

    </div>
  );
};

export default RankingChart;