// src/common/components/InitialIcon.tsx
import React from "react";

interface InitialIconProps {
    initials: string;
}

const InitialIcon: React.FC<InitialIconProps> = ({ initials }) => {
    return (
        <div className="bg-blue-400 flex items-center font-semibold justify-center rounded-full w-14 h-14 text-white text-lg">
            {initials}
        </div>
    );
};

export default InitialIcon;
