import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import authService from "../services/authService";
import AuthForm from "../components/AuthForm";

const ResetPassword: React.FC = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [tokenValid, setTokenValid] = useState(false);
    const token = searchParams.get("token");

    useEffect(() => {
        const checkToken = async () => {
            try {
                await authService.checkResetToken(token);
                setTokenValid(true);
            } catch (error) {
                toast.error("Lien utilisé ou invalide");
                setTokenValid(false);
            }
        };

        if (token) {
            checkToken();
        } else {
            toast.error("Lien utilisé ou invalide");
            setTokenValid(false);
        }
    }, [token]);

    const onSubmit = async (data: any) => {
        if (data.password !== data.confirmPassword) {
            toast.error("Les mots de passe ne correspondent pas");
            return;
        }

        try {
            await authService.changePassword(token, data.password);
            toast.success("Votre mot de passe a bien été changé");
            setTimeout(() => {
                navigate("/login");
            }, 2000);
        } catch (error) {
            toast.error("Erreur lors du changement de mot de passe");
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <Toaster />
            <div className="absolute top-10 text-center w-full">
                <h1 className="text-3xl font-bold">Fideliz</h1>
            </div>
            {tokenValid ? (
                <div className="bg-white p-8 rounded-lg w-full max-w-md">
                    <h2 className="text-2xl font-bold mb-6 text-center">Changer le mot de passe</h2>
                    <AuthForm
                        onSubmit={onSubmit}
                        register={register}
                        handleSubmit={handleSubmit}
                        errors={errors}
                        fields={[
                            { label: "Nouveau mot de passe", type: "password", name: "password", validation: { required: "Mot de passe est requis" } },
                            { label: "Confirmer le mot de passe", type: "password", name: "confirmPassword", validation: { required: "Confirmation est requise" } }
                        ]}
                        submitButtonText="Changer le mot de passe"
                    />
                </div>
            ) : (
                <>
                    <div className="absolute left-0 top-10 text-center w-full">
                        <h1 className="text-3xl font-bold">Fideliz</h1>
                    </div>
                    <p className="text-gray-700 text-center font-semibold">
                        Ce lien a déjà été utilisé ou est expiré.
                        <br /> Veuillez contacter Fideliz pour plus d'informations.
                    </p>
                </>
            )}
        </div>
    );
};

export default ResetPassword;
